<template>
  <base-view id="login">
    <view-header title="Login / Register" image="bg-login.jpg">
      Looking to login or register? Please select one of the options below so we
      can get you to the right place.
    </view-header>
    <view-section>
      <div class="flex flex-col flex-wrap justify-center md:flex-row">
        <router-link
          to="/login/participant"
          class="px-6 py-8 mx-auto mt-0 mb-8 text-center transition-colors rounded-md shadow-md bg-neutral-lighter group md:mx-8 hover:text-white hover:bg-primary-light active:bg-primary"
        >
          <span
            class="inline-block text-primary text-7xl group-hover:text-white flaticon-lineal"
          />
          <p class="m-0 mt-2 text-2xl font-semibold">
            Participants / Employees
          </p>
          <p class="m-0 mt-4">For those who have a benefits plan.</p>
        </router-link>
        <router-link
          to="/login/employer"
          class="px-6 py-8 mx-auto mt-0 mb-8 text-center transition-colors rounded-md shadow-md bg-neutral-lighter group md:mx-8 hover:text-white hover:bg-primary-light active:bg-primary"
        >
          <span
            class="inline-block text-primary text-7xl group-hover:text-white flaticon-management"
          />
          <p class="m-0 mt-2 text-2xl font-semibold">Employers</p>
          <p class="m-0 mt-4">For client HR departments.</p>
        </router-link>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection
  }
};
</script>
